import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class LocalService {
  backupStorage: any = {};
  storageMode = 'localStorage';

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    try {
      localStorage.length;
    } catch (e) {
      try {
        sessionStorage.length;
        this.storageMode = 'sessionStorage';
      } catch (e) {
        this.storageMode = 'backupStorage';
      }
    }
  }

  public saveData(key: string, value: string) {
    if (isPlatformBrowser(this.platformId)) {
      switch (this.storageMode) {
        case 'localStorage':
          localStorage.setItem(key, value);
          break;
        case 'sessionStorage':
          sessionStorage.setItem(key, value);
          break;
        case 'backupStorage':
          this.backupStorage[key] = value;
          break;
      }
    }
  }

  public getData(key: string) {
    if (isPlatformBrowser(this.platformId)) {
      switch (this.storageMode) {
        case 'localStorage':
          return localStorage.getItem(key);
        case 'sessionStorage':
          return sessionStorage.getItem(key);
        case 'backupStorage':
          return this.backupStorage[key];
      }
    }
    return null;
  }

  public removeData(key: string) {
    if (isPlatformBrowser(this.platformId)) {
      switch (this.storageMode) {
        case 'localStorage':
          return localStorage.removeItem(key);
        case 'sessionStorage':
          return sessionStorage.removeItem(key);
        case 'backupStorage':
          delete this.backupStorage[key];
      }
    }
  }

  public clearData() {
    if (isPlatformBrowser(this.platformId)) {
      switch (this.storageMode) {
        case 'localStorage':
          return localStorage.clear();
        case 'sessionStorage':
          return sessionStorage.clear();
        case 'backupStorage':
          this.backupStorage = {};
      }
    }
  }
}
